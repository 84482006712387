@import "src/styles/variables";

.attachment-upload {
  box-sizing: border-box;
}

.attachment-upload__input {
  width: 100%;
  padding: 2rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000008;
  border: 2px dashed #d3d3d3;
  border-radius: 6px;
  margin-top: 1rem;
  .ant-spin.ant-spin-lg.ant-spin-spinning.app-loader {
    margin-top: 0;
    .ant-spin-dot {
      font-size: 2rem;
    }
  }

  .attachment-upload__content {
    img {
      width: 24px;
      height: 19px;
    }
    p {
      padding-top: 1rem;
      font-size: 13px;
      color: #597bcd;
    }
  }
}

.attachment-upload__icon {
  display: block;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.attachment-upload__img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.attachment-upload__title {
  margin: 0 0 6px 0;
  color: $secondary-black;
  font-weight: 500;
}
