.setting-wrapper {
  .ant-tabs-content-holder {
    .ant-tabs-tabpane {
      padding: 0 !important;
    }
  }

  h1 {
    span,
    button {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
