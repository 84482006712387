@import "src/styles/variables";

.notification-bar {
  .profile-pic {
    width: 38px;
    height: 38px;
    border-radius: 25px;
  }
  .message-unread {
    background: #f3f6ff 0% 0% no-repeat padding-box;
  }
  .notification-bar__value {
    cursor: pointer;
    padding: 1rem;

    margin-bottom: 1px;
    border-bottom: 0.5px solid $primary-border;
  }
  .horizontal-line {
    height: 0.5px;
    width: 100%;
    background-color: $primary-border;
  }
  .notification-bar__title {
    font-size: 16px;
  }
}
