@import "src/styles/variables";

.customer-existing-loan {
  padding: 2rem;
}

.customer-existing-loan__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.customer-existing-loan__value {
  font-family: SFProDisplaySemibold, sans-serif;
}
