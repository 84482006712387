@import "src/styles/variables";

.customer-form {
  .customer-form__form-wrapper {
    margin: 1.5rem;
  }

  .customer-form__form-card {
    box-shadow: $primary-shadow;
    padding: 3rem;
    border-radius: 8px;
    background-color: #FFFFFF;
  }
}