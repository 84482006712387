@import "src/styles/variables";

.radio-field {
  .ant-radio-group {
    display: block;
    margin-top: 0.5rem;
    .ant-radio-wrapper {
      .ant-radio-checked {
      }
    }
  }
  .ant-radio-checked .ant-radio-inner::after {
    border-color: green !important;
  }
}
