.status-container {
  padding: 0.2rem;
  height: 30px;
  border-radius: 7px;
  opacity: 1;
  text-align: center;
}
.payment-completed,
.completed,
.active,
.refunded {
  @extend .status-container;
  background-color: #e5ffe2;
  color: #26ad10;
}

.profile {
  @extend .status-container;
  background-color: #e0ecff;
  color: #1058ad;
}
.payment {
  @extend .status-container;
  background-color: #ede0ff;
  color: #5210ad;
}
.expired,
.payment_failed {
  @extend .status-container;
  background-color: #ffe0e0;
  color: #ad1010;
}
