@import "src/styles/variables";

.forgot-password-form {
  h1 {
    color: $primary-color;
    span {
      font-size: 1rem;
      color: $secondary-black;
      font-weight: normal;
    }
  }
  margin-top: 10%;
  width: 75%;
  background-color: $white;
  padding: 3rem 4rem;
  box-shadow: $primary-shadow;
  border-radius: 8px;

  .ant-btn.ant-btn-primary {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .forgot-password-form {
    margin: 6% auto 0 auto;
    padding: 2rem;
    width: 90%;
  }
}
