.ba-dashboard__data {
  .ant-row {
    justify-content: space-between;

    .ant-col {
      .ant-card {
        border-radius: 8px;
        .ant-card-body {
          width: 200px;
          height: 151px;
          text-align: center;
          border-radius: 3px;
          margin: 1rem !important;
          p {
            color: #40414e !important;
          }
        }
      }
    }
  }
}
