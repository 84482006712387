.notification-form {
  .notification-form__form {
    .notification-form__switch {
      .ant-switch-handle::before {
        background-color: #363a7c;
        margin-bottom: 0.8px !important;
      }
      .ant-switch {
        background-color: transparent !important;
        border: 1px solid #363a7c !important;
      }
    }
    .ant-row {
      .ant-col {
        margin-top: 0.7rem !important;
        .input-field-wrapper textarea {
          height: 100px !important;
        }
      }
    }
  }
  .notification-form__heading {
    display: flex;
  }
  .text-green {
    color: #219819;
  }
}
