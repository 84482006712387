.ba-additional-info {
  padding: 2rem;
  .ba-additional-info__edit img {
    cursor: pointer;
  }
  .ba-additional-info__edit img {
    width: 20px;
  }

  .ant-row {
    .ant-col {
      max-width: 100%;
    }
  }
}
