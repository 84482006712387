@import "./../../../styles/variables";

.table__container {
  width: 100%;
  overflow-x: scroll;
  box-shadow: 0px 0px 24px #3e3f5e0f;

  .ant-table-wrapper {
    .ant-table {
      .ant-pagination-options {
        display: none !important;
      }

      .ant-table-container {
        .ant-table-content {
          font-size: 14px;

          .ant-table-thead {
            background-color: #f8fafb;
            border-radius: 10px 0 10px 0;
            border: 1px solid silver;
            overflow-y: scroll;

            tr {
              th {
                white-space: nowrap;
                color: #303030;
                background: transparent;
              }

              th:first-child {
                border-top-left-radius: 10px;
              }

              th:last-child {
                border-top-right-radius: 10px;
              }
            }
          }

          .ant-table-tbody {
            tr {
              height: 0.5rem;
            }

            tr:last-child {
              border-radius: 0 0 20px 20px;

              td {
                white-space: nowrap;
              }

              td:first-child {
                border-radius: 0 0 0 10px;
              }

              td:last-child {
                border-radius: 0 0 20px 0;
              }
            }
          }
        }
      }
    }


    .ant-pagination.ant-table-pagination {

      .ant-pagination-total-text {
        position: absolute;
        left: 0;

        p {
          font-size: 14px;
          margin: 0;
        }
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        margin: 0;
        min-width: 24px;
        height: 24px;

        .ant-pagination-item-link {
          min-width: 24px;
          height: 24px;
          border: 0;
          line-height: 25px;

          .anticon {
            color: $primary-color;
          }
        }
      }

      .ant-pagination-item {
        margin: 0;
        min-width: 24px;
        height: 24px;
        border: none;

        a {
          margin: 0;
          min-width: 24px;
          height: 24px;
          line-height: 25px;
          border-radius: 6px;
          background-color: #fff;
          color: #000;

          &:hover {
            color: #000;
          }
        }


        &.ant-pagination-item-active {

          a {
            background-color: $primary-color;
            color: #fff;
          }
        }
      }

      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        &:hover {

          .ant-pagination-item-link {
            .ant-pagination-item-container {

              .ant-pagination-item-ellipsis {
                opacity: 1;
              }
            }
          }
        }
      }

      .ant-pagination-options {
        .ant-select.ant-pagination-options-size-changer {
          display: none;

        }
      }
    }

  }
}


.ant-table-empty .ant-table-tbody>tr.ant-table-placeholder td {
  padding: 0 1rem !important;
}

.anticon.anticon-double-right.ant-pagination-item-link-icon,
.anticon.anticon-double-left.ant-pagination-item-link-icon {
  display: none !important;
}

.ant-pagination-item-ellipsis {
  opacity: 1 !important;
}

.ant-select ant-pagination-options-size-changer ant-select-single ant-select-show-arrow>.ant-select-selector {
  display: none;
}

// .table__container.holdings-table {
//   .ant-table-wrapper {
//     .ant-table {
//       .ant-table-container {
//         .ant-table-content {

//           .ant-table-thead,
//           .ant-table-tbody {
//             .ant-table-cell {
//               padding: 1rem 1rem 1rem 1rem !important;
//             }
//           }
//         }
//       }
//     }
//   }
// }