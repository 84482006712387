@import "src/styles/variables";

.customer-detail {
  .customer-detail__edit {
    position: absolute;
    top: 1rem;
    right: 1rem;
    svg {
      font-size: 1.5rem;
      color: $primary-color;
    }
  }
  .ba-detail__notes-title {
    margin-bottom: 100px;
  }
}

.customer-detail__contact {
  margin-top: 2rem;
  > .anticon {
    &:first-child {
      margin-right: 1.5rem;
    }
    svg {
      font-size: 1.5rem;
      color: $primary-color;
    }
  }
}

.customer-detail__profile-card {
  position: relative;
  padding: 2rem 1rem 1rem;
  box-shadow: $primary-shadow;
  background: $white;
  border-radius: 8px;
  margin-top: 2.3rem;
  text-align: center;

  .customer-detail__profile-pic {
    display: block;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50em;
    margin: 0 auto 1rem auto;
  }

  .ant-select.customer-detail__status-select:not(.ant-select-customize-input) {
    border-radius: 8px;
    .ant-select-selector {
      border: none;
      border-radius: 8px;
      background-color: $select-bg;
    }
  }
}
