@import "src/styles/variables";

.detail-list {
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      width: 28%;
      // margin-left: 1rem;
    }
  }
}
