@import "src/styles/variables";

.product-request-list {
  .ant-input-group-wrapper.ant-input-search.product-request-list__search {
    border: 1px solid $primary-border;
    border-radius: 8px;
    input {
      border-radius: 8px 0 0 8px;
    }
    .ant-btn.ant-btn-icon-only.ant-input-search-button {
      border-radius: 0 8px 8px 0;
    }
    .ant-input-group-addon {
      border-radius: 0 8px 8px 0;
    }
    input,
    .ant-input-group-addon .ant-btn.ant-btn-icon-only.ant-input-search-button {
      // background: $white;
      // border: none;
      // padding: 10px;
      // height: 40px;
    }
    input:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
