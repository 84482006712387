/* Color palette */
$primary-color: #363a7c;
$secondary-color: #f3b926;
$white: #ffffff;
$light-white: #ffffff15;
$primary-black: #000000;
$primary-light-color: #0d494e10;
$primary-black: #4b4b4b;
$secondary-black: #4f4f4f;
$primary-border: #bebebe;
$dark-blue: #22509d;
$text-grey: #9e9e9e;
$light-grey: #f5f5f5;
$dark-grey: #8898aa;
$success-color: #26ad10;
$danger-color: #ff503c;
$warning-color: #f3b21b;
$body-bg: #f2f2e5;
$table-bg: #f7fafc;
$input-bg: #f8fbfc;
$timeline-border: #c9c9c9;
$select-bg: #f1f1d3;

/* Shadows */
$primary-shadow: 0px 12px 24px #00000014;
$secondary-shadow: 0px 3px 6px #2c28281c;

/* Spacer variables */
$spacer-x: 1rem;
$spacer-y: 1rem;
