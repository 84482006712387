@import "src/styles/variables";

.ant-btn {
  padding: 7px 25px;
  height: auto;
  border-radius: 8px;
  font-size: 1rem;
  &.rounded-btn {
    border-radius: 50rem;
  }
  &.ant-btn-icon-only {
    padding: 6px 10px;
    width: auto;
  }
  &.ant-btn-primary {
    color: $white;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    &:disabled,
    &[disabled] {
      color: $white;
      border: 1px solid $primary-color;
      background-color: $primary-color;
      opacity: 0.8;
    }
  }
  &.ant-btn-link {
    color: $primary-color;
    padding: 0 3px;
  }
  &.secondary-btn {
    color: $primary-color;
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    &:hover {
      border: 1px solid $secondary-color;
    }
    &:disabled,
    &[disabled] {
      color: $primary-color;
      border: 1px solid $secondary-color;
      background-color: $secondary-color;
      opacity: 0.8;
    }
  }
}

/* Checkbox styles */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color;
  border-color: $primary-color;
}

/* Modal styles */
.ant-modal {
  border-radius: 8px;
  font-size: 1rem;
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    border-radius: 8px;
    font-size: 1rem;
    padding: 3rem;
  }
  @media (max-width: 768px) {
    width: 90% !important;
    .ant-modal-body {
      padding: 2rem;
    }
  }
}

/* Table overrides */
.ant-table-wrapper {
  border-radius: 8px;
  .ant-table {
    box-shadow: $primary-shadow;
    border-radius: 8px;
    .ant-table-container,
    .ant-table-content,
    .ant-table-thead {
      border-radius: 8px;
    }
    .ant-table-thead {
      padding-top: 1rem;
      padding-bottom: 1rem;
      .ant-table-cell {
        text-transform: capitalize;
        font-family: SFProDisplaySemibold, sans-serif;
        background-color: $white;
      }
    }
    .ant-table-thead th:first-child {
      border-radius: 8px 0 0 0;
      padding-left: 2rem;
    }
    .ant-table-thead th:last-child {
      border-radius: 0 8px 0 0;
      padding-right: 2rem;
    }

    .ant-table-tbody {
      .ant-table-row {
        cursor: pointer;
        > .ant-table-cell {
          padding-top: 1rem;
          padding-bottom: 1rem;
          &:first-child {
            padding-left: 2rem;
          }
          &:last-child {
            padding-right: 2rem;
          }
        }
      }
    }
  }
}

/* Tabs overrides */
.ant-tabs.ant-tabs-top.ant-tabs-small {
  > .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-tab {
    &:hover {
      color: $primary-color;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary-color;
    font-family: SFProDisplaySemibold, sans-serif;
  }
  .ant-tabs-ink-bar {
    background: $primary-color;
  }

  .ant-tabs-tabpane.ant-tabs-tabpane-active {
    background: $white;
    padding: 2rem;
    border-radius: 8px;
    width: 100%;
    box-shadow: $primary-shadow;
  }
}

.ant-tabs.ant-tabs-left {
  .ant-tabs-nav {
    border-right: none;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: transparent;
  }
  .ant-tabs-content-holder {
    border: none;
  }
  .ant-tabs-tab {
    border-bottom: 1px solid $primary-border;
    padding: 0.5rem;
    margin-bottom: 0;
    &:hover {
      color: $primary-color;
    }
    .ant-tabs-tab-btn {
      border: 1px solid transparent;
      border-radius: 8px;
      padding: 5px 15px;
    }
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        background-color: $secondary-color;
        color: $primary-color;
        border: 1px solid $secondary-color;
        border-radius: 8px;
      }
    }
  }
}
.ant-pagination {
  .ant-pagination-total-text {
    line-height: 37px !important;
  }
}
