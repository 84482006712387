@import "src/styles/variables";

.brokerage-group-list {
  ul {
  }
}

ul.brokerage-group-list__ul {
  list-style: none;
  padding-left: 15px;
  .brokerage-group-list__percentage {
    color: $warning-color;
  }
}