.activity-log {
  .ant-col {
    .ant-select {
      width: 277px !important;

      opacity: 1;
      .ant-select-selector {
        border-radius: 4px !important;
        height: 38px !important;
        .ant-select-selection-placeholder {
          margin-top: 0.2rem;
        }
        .ant-select-selection-item {
          margin-top: 0.2rem;
        }
        .anticon-search {
          width: 30px !important;
        }
      }
    }
  }
}
