@import "src/styles/variables";

.my-learning-list {
  h1 {
    span,
    button {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .my-learning-list__icons {
    opacity: 0;
    transition: opacity 0.25s ease;
    .anticon {
      margin-right: 10px;
      svg {
        font-size: 1.2rem;
      }
    }
  }
  .ant-table-wrapper {
    .ant-table-content {
      .ant-table-row:hover {
        .my-learning-list__icons {
          opacity: 1;
        }
      }
    }
  }

  .ant-select.my-learning-list__status-select:not(.ant-select-customize-input) {
    border-radius: 8px;
    .ant-select-selector {
      border: none;
      border-radius: 8px;
      background-color: $select-bg;
    }
  }
}

.ant-input-group-wrapper.ant-input-search.my-learning-list__search {
  input,
  .ant-input-group-addon .ant-btn.ant-btn-icon-only.ant-input-search-button {
    background: $white;
    border: none;
    padding: 10px;
    height: 40px;
  }
  input:focus {
    outline: none;
    box-shadow: none;
  }
}
.notes {
  text-align: justify;
}
