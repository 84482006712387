@import "src/styles/variables";

.user-personal-details {
  padding: 2rem;
}

.user-personal-details__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.user-personal-details__value {
  font-family: SFProDisplaySemibold, sans-serif;
}

.user-personal-details__location-item {
  background-color: $light-grey;
  border-radius: 50em;
  padding: 5px 15px;
  margin-right: 10px;
}
