@import "src/styles/variables";

.assign-brokerage-group {
  ul.assign-brokerage-group__ul {
    list-style: none;
    padding-left: 15px;
    .assign-brokerage-group__percentage {
      color: $warning-color;
    }
  }
  .assign-brokerage-group__show-wrapper {
    background-color: $light-grey;
    padding: 1rem;
    border-radius: 8px;
  }
  .ant-radio-wrapper {
    width: 30% !important;
  }
}
