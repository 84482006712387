@import "src/styles/variables";
.third-party-verifications {
  padding: 2rem;

  &:not(:last-child) {
    border-bottom: 1px solid $primary-border;
    padding-bottom: 1.5rem;
  }
  &:not(:first-child) {
    padding-top: 1.5rem;
  }
}
