@import "src/styles/variables";

.ba-list {
  .ba-list__icons {
    .anticon {
      margin-right: 10px;
      svg {
        font-size: 1.2rem;
      }
    }
  }
  h1 {
    span,
    button {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .ant-btn.ba-list__filter-btn {
    background-color: $white;
    border: none;
    margin-right: 10px;
    border-radius: 4px;
  }
}

.check_status__logo {
  width: 60px !important;
}

.ant-select.ba-list__status-select:not(.ant-select-customize-input) {
  border-radius: 8px;
  .ant-select-selector {
    text-align: center;
    border: none;
    border-radius: 8px;
    background-color: $select-bg;
  }
}

.ba-list__back-icon {
  cursor: pointer;
}

.ant-input-group-wrapper.ant-input-search.ba-list__search {
  input,
  .ant-input-group-addon .ant-btn.ant-btn-icon-only.ant-input-search-button {
    background: $white;
    border: none;
    padding: 10px;
    height: 40px;
    .ba-list__search {
      .ant-input-group-wrapper {
        border: 0.1px solid #c0c0c0;
        border-radius: 3px;
      }
    }
  }
  .ant-input-group-wrapper.ant-input-search.ba-list__search {
    input,
    .ant-input-group-addon .ant-btn.ant-btn-icon-only.ant-input-search-button {
      border: none;
      padding: 10px;
      height: 40px;
    }
    input:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
.ant-modal-content {
  .ant-modal-body {
    padding: 2rem !important;
  }
  .ant-modal-footer {
    border: none !important;
    text-align: center !important;
    padding-top: 0;
    button {
      border: none;
    }
  }
}
.ba-list__icons {
  .contact-image {
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }
}

.ant-table-cell {
  .status-info {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}
.contact-profile__default-image {
  .contact-profile__image {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    margin-left: 3px;
  }
}

.contact-profile__default-image {
  height: 47.5px;
  width: 47.5px;
  border-radius: 50%;
  display: inline-block;
  background-color: $light-grey;
  // text-align: center;
  // padding-top: 10px;
}
