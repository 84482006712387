@import "src/styles/variables";

.product-request-comments {
  padding: 2rem;
}

.ant-input.product-request-comments__update-input {
  width: 100%;
  border-radius: 8px;
  padding: 10px 15px;
  background-color: $light-grey;
  border: none;
}

.product-request-comments__update-wrapper {
  position: relative;
  .ant-btn.ant-btn-primary.ant-btn-icon-only {
    position: absolute;
    top: 2px;
    right: 8px;
  }
}
