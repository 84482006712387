@import "src/styles/variables";

.customer-list {
  h1 {
    span,
    button {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .ant-input-group-wrapper.ant-input-search.customer-list__search {
    input,
    .ant-input-group-addon .ant-btn.ant-btn-icon-only.ant-input-search-button {
      background: $white;
      border: none;
      padding: 10px;
      height: 40px;
    }
    input:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .filter-wrapper {
    .ba-list__filter-btn {
      margin-right: 1rem;
      background-color: $white;
      border: none;
      margin-right: 10px;
      border-radius: 4px;
    }
  }
}
