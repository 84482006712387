@import "variables";
@import "helpers";
@import "antOverrides";

/* General component styles */

/* General component styles */

@font-face {
  font-family: SFProDisplayBlack;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Black.ttf")
    format("truetype");
}

@font-face {
  font-family: SFProDisplayBold;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: SFProDisplayHeavy;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Heavy.ttf")
    format("truetype");
}

@font-face {
  font-family: SFProDisplayMedium;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: SFProDisplayRegular;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: SFProDisplaySemibold;
  src: url("../assets/fonts/FontsFree-Net-SFProDisplay-Semibold.ttf")
    format("truetype");
}

body {
  background-color: $body-bg;
  color: $primary-black;
  font-size: 1rem !important;
  font-family: SFProDisplayRegular, sans-serif !important;
  width: 100%;
  overflow: hidden auto;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input {
  font-family: SFProDisplayRegular, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

.App {
  width: 100%;
  overflow: hidden auto;
}

.app-wrapper {
  margin-left: 18%;
  padding: 2rem;
}

@media (max-width: 768px) {
  .app-wrapper {
    margin: 0;
    padding: 0;
  }
}

.ant-input-group-wrapper.ant-input-search {
  input,
  .ant-input-group-addon .ant-btn.ant-btn-icon-only.ant-input-search-button {
    background: $input-bg;
    border: none;
    padding: 10px;
    height: 40px;
    border-radius: 8px !important;
  }
  input:focus {
    outline: none;
    box-shadow: none;
  }
}

.ant-select.select-filter:not(.ant-select-customize-input) {
  .ant-select-selector {
    background: $white;
    border: none;
    padding: 5px 10px;
    height: auto;
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.full-width {
  width: 100%;
}
