@import "src/styles/variables";

.ba-filter-form {
  .ant-tabs-content-holder {
    margin-left: 2rem !important;
  }
  .ant-checkbox-group {
    width: 100%;
    .ant-checkbox-wrapper.ant-checkbox-group-item {
      width: 28%;
      vertical-align: top;
      margin-left: 1rem;
      margin-bottom: 0.5rem;
      > span {
        display: inline-block;
        vertical-align: top;
        &:first-child {
          margin-top: 0.25rem;
        }
        &:last-child {
          width: 88%;
        }
      }
      .ant-checkbox-input {
        &:focus + .ant-checkbox-inner {
          outline: none;
          border-color: transparent;
        }
      }
    }
  }
}
.ant-drawer-body {
  padding: 0.5rem !important;
}
