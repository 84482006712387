@import "src/styles/variables";

.customer-additional-information {
  padding: 2rem;
  .ant-row {
    margin-bottom: 1rem;
  }
}

.customer-additional-information__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.customer-additional-information__value {
  font-family: SFProDisplaySemibold, sans-serif;
}
