@import "src/styles/variables";

.verify-otp-wrapper {
  h1 {
    color: $primary-color;
    span {
      font-size: 1rem;
      color: $secondary-black;
      font-weight: normal;
    }
  }
  margin-top: 20%;
  width: 80%;
  background-color: $white;
  padding: 3rem 0;
  box-shadow: $primary-shadow;
  border-radius: 8px;

}


.verify-otp-form__input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    color: $secondary-black;
    font-size: 1.5rem;
    width: 2rem !important;
    border: none;
    border-bottom: 1px solid $primary-border;
    font-weight: bold;
    &:focus {
      outline: none;
    }
  }
  span {
    opacity: 0;
    width: 15px;
  }
}

.verify-otp-wrapper__email-verified {
  //margin-top: 30%;
  text-align: center;
  .anticon {
    border: 3px solid $success-color;
    border-radius: 50em;
    padding: 10px;
    font-size: 2rem;
    svg {
      color: $success-color;
    }
  }
  p {
    font-weight: bold;
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .verify-otp-wrapper {
    margin: 6% auto 0 auto;
    padding: 2rem;
    width: 90%;
    .right-border {
      border: none !important;
    }
    .ant-col-xs-24 {
      margin-bottom: 3rem;
    }
  }
}
