.ant-table-cell {
  img {
    width: 40px;
    height: 40px;
  }
}
.banner-upload__img {
  width: 57px;
  height: 38px;

  /* UI Properties */

  border-radius: 4px;
  opacity: 1;
}

.banner {
  .banner__table {
    .ant-table-content {
      .ant-table-thead {
        background-color: transparent !important;
      }
    }
  }
}
