@import "src/styles/variables";

.dropdown-field {
  margin-bottom: 1.5rem;

  .dropdown-field__title {
    color: $secondary-black;
    margin-bottom: 10px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select.ant-select-multiple.ant-select-show-search .ant-select-selector {
    padding: 5px 15px;
    border: 1px solid $primary-border;
    height: auto;
    border-radius: 4px;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 15px;
  }
  .ant-select-multiple .ant-select-selection-search {
    margin-inline-start: -2px;
  }

  .ant-select-multiple
    .ant-select-selection-search:first-child
    .ant-select-selection-search-input {
    margin-left: 0;
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100%;
    padding-left: 4px;
  }

  .ant-select {
    width: 100%;
  }
}
