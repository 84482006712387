@import "src/styles/variables";

.internal-user-form {
}

.internal-user-form__form {
  padding: 2rem;
  background: $white;
  box-shadow: $primary-shadow;
  border-radius: 8px;
}

.internal-user-form__content-wrapper {
  margin: 0 2rem;
}
