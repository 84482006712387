@import "src/styles/variables";

.order-list {
  border: none !important;
  .uploadIcon img {
    width: 36px;
    height: 35px;
    cursor: pointer;
  }
  .ant-pagination-options {
    display: none;
  }

  .status-container {
    padding: 0.2rem;
    height: 30px;
    border-radius: 7px;
    opacity: 1;
    text-align: center;
  }
  .payment_pending,
  .video_kyc {
    @extend .status-container;
    background-color: #fff4e2;
    color: #ffad64;
  }
  .payment_pending,
  .video_kyc,
  .pending_vendor_confirmation {
    @extend .status-container;
    background-color: #fff4e2;
    color: #ffad64;
  }

  .payment_completed,
  .completed,
  .active,
  .refunded {
    @extend .status-container;
    background-color: #e5ffe2;
    color: #26ad10;
  }
  .profile {
    @extend .status-container;
    background-color: #e0ecff;
    color: #1058ad;
  }
  .payment {
    @extend .status-container;
    background-color: #ede0ff;
    color: #5210ad;
  }
  .expired,
  .payment_failed {
    @extend .status-container;
    background-color: #ffe0e0;
    color: #ad1010;
  }
  &__dropdown {
    .rc-virtual-list {
      .rc-virtual-list-holder {
        max-height: 300px !important;
        overflow-y: visible !important;
      }
    }
  }
}
