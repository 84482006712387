@import "src/styles/variables";

.ba-detail {
  .ba_uia {
    font-size: 14px;
    color: $secondary-black;
  }
}

.ba-detail__profile-card {
  padding: 1rem 1.5rem;
  box-shadow: $primary-shadow;
  background: $white;
  border-radius: 8px;
  margin-top: 2rem;
  text-align: center;
  .ba-details__status {
    row-gap: 10px !important;
  }
  .contact-image {
    width: 18px;
    height: 18px;
    font-size: 12px !important;
  }
  .alert-image {
    display: flex;
    justify-content: center;
    font-size: 14px;
    img {
      margin-right: 5px;
      width: 22px;
      height: 22px;
    }
  }

  .ba-detail__profile-pic {
    display: block;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50em;
    margin: 0 auto 1rem auto;
  }
  .text-active {
    font-size: 12px;
  }

  .ant-select.ba-detail__status-select:not(.ant-select-customize-input) {
    border-radius: 8px;
    .ant-select-selector {
      border: none;
      border-radius: 8px;
      background-color: $select-bg;
    }
  }
}

.ba-detail__note {
  margin-top: 15px;
  img {
    width: 100%;
    border-radius: 50rem;
  }
  .ba-detail__note-label {
    color: $primary-color;
  }
}
.ba-details__tooltip {
  border-radius: 12px !important;
}
