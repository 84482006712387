@import "src/styles/variables";

.order-general-details {
  padding: 2rem;
}

.order-general-details__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.order-general-details__value {
  font-family: SFProDisplaySemibold, sans-serif;
}
