@import "src/styles/variables";

.assign-ba {
}

.ant-radio-group.assign-ba__radio-group {
  margin-top: 2rem;
  width: 100%;

  .ant-radio-wrapper {
    width: 32%;
    margin-right: 0.5rem;

    overflow: hidden;
    span {
      word-wrap: break-word !important;
    }
  }
}

.ant-input-group-wrapper.ant-input-search.assign-ba__search {
  input,
  .ant-input-group-addon .ant-btn.ant-btn-icon-only.ant-input-search-button {
    background: $white;
    padding: 10px;
    height: 40px;
  }
  input,
  .ant-input-group-addon {
    border-radius: 8px 0 0 8px;
  }
  .ant-btn.ant-btn-icon-only.ant-input-search-button {
    border-left: none;
    border-radius: 0 8px 8px 0;
  }
  input:focus {
    outline: none;
    box-shadow: none;
  }
}
