@import "src/styles/variables";

.verify-otp-form {
  text-align: center;
  padding: 0 2rem;
  &.disabled {
    opacity: 0.3;
    user-select: none;
    cursor: not-allowed;
    input {
    cursor: not-allowed;
    }
  }
}
