@import "src/styles/variables";

.app-sidebar {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 18%;
  height: 100vh;
  padding: 2rem 1rem;
  background-color: $primary-color;
  border-radius: 0 20px 20px 0;
  color: $white;
  .app-sidebar__content {
    height: 94vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
}

.app-sidebar__logo {
  width: 100%;
}

.ant-menu.app-sidebar__popup.ant-menu-vertical {
  border: none;
  margin-bottom: 10px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    object-fit: cover;
  }
}
.ant-menu.app-sidebar__menubar.ant-menu-vertical {
  border: none;
  margin-top: 3rem;
  background-color: $primary-color;
  .ant-menu-item {
    font-family: SFProDisplayMedium, sans-serif;
    background-color: $primary-color;
    color: $white;
    border-radius: 4px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      object-fit: cover;
    }
    span {
      height: 40px;
    }
    img,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    &:hover {
      background-color: $light-white;
    }
    &.ant-menu-item-selected,
    &.ant-menu-item-active {
      background-color: $light-white;
    }
    &.app-sidebar__logout-item {
      span {
        height: auto;
      }
    }
  }
}
