@import "src/styles/variables";

.ba-commission-list,
.business-credit-points {
  padding: 1rem;
  .ant-table {
    width: 100%;
    overflow: scroll;
  }
  .ba-commission-list__btn-group,
  .business-credit-points__btn-group {
    > .ant-btn {
      padding-left: 40px;
      padding-right: 40px;
      border: none;
      background-color: #f2f2f6;
      border-radius: 50rem;
      position: relative;
      z-index: 1;
      &:not(:first-child) {
        margin-left: -30px;
      }
      &.active {
        background: $secondary-color;
        color: $white;
        z-index: 2;
      }
    }
  }
}
