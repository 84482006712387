@import "src/styles/variables";

.customer-personal-details {
  padding: 2rem;
}

.customer-personal-details__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.customer-personal-details__value {
  font-family: SFProDisplaySemibold, sans-serif;
}
