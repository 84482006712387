@import "src/styles/variables";

.service-provider-basic-details {
  padding: 2rem;
  .service-provider-basic-details__label {
    color: $secondary-black;
    margin-bottom: 5px;
  }

  .service-provider-basic-details__value {
    font-family: SFProDisplaySemibold, sans-serif;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .service-provider-basic-details__name {
    font-family: SFProDisplayBold, sans-serif;
  }

  .service-provider-basic-details__contact-person {
    margin-bottom: 15px;
  }

  .service-provider-basic-details__contact-person {
    svg {
      font-size: 18px;
      margin-right: 10px;
    }
  }
}
