.survey-questions-form {
  .survey-questions__delete-btn {
    width: 30px;
    margin-top: 5px;
    cursor: pointer;
  }
  .survey-questions__add-answer {
    font-weight: 900;
    cursor: pointer;
  }
  .attachment-upload__input {
    height: 100px;
    margin-top: 0.3rem !important;
  }
}
.survey-heading {
  padding-left: 20px !important;
}
