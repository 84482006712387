@import "src/styles/variables";

.ba-additional-info-form {
  .ba-additional-info-form__family-member {
    margin-bottom: 4px;
    .family-member__age {
      padding-left: 15rem;
    }
  }
  .ant-row {
    .input-field-wrapper {
      .input-field__title {
        color: $text-grey;
      }
    }
    .dropdown-field {
      .dropdown-field__title {
        color: $text-grey;
      }
      .ant-select-arrow {
        color: black;
      }
    }
  }
  .save-btn button {
    position: fixed;
    top: 90%;
    left: 90%;
  }
  .cancel-btn button {
    position: fixed;
    top: 90%;
    left: 82%;
  }
  .delete-icon {
    width: 30px;
    margin-top: 8px;
    cursor: pointer;
  }
}
// .ant-drawer-body {
//   padding: 3rem !important;
// }

.family-members {
  margin-bottom: 20px !important;
}
