@import "src/styles/variables";

.learning-module-form {
  .learning-module-form__module-type {
    .divider {
    }
  }
  .ant-row {
    .ant-radio-group {
      margin-top: 43px;
      display: flex;
      justify-content: flex-start;
      .ant-radio-wrapper {
        width: auto;
        span {
          font-size: 15px;
        }
      }
    }
  }
  .learning-module-form__form {
    .ant-row {
      .attachment-upload {
        .attachment-upload__input {
          height: 150px !important;
          border: 1.8px dotted;
          span {
            margin-top: 2rem;
          }
        }
      }
    }
  }
  .applicable-user {
    .ant-radio-group {
      .ant-radio-wrapper {
        margin: 0 !important;
      }
    }
  }
  .save-btn button {
    position: fixed;
    top: 90%;
    left: 90%;
  }
  .cancel-btn button {
    position: fixed;
    top: 90%;
    left: 82%;
  }
  .ant-col {
    margin-bottom: 1rem;
    .input-field-wrapper {
      textarea {
        height: 135px !important;
      }
    }
    .ant-radio-group {
      margin-bottom: 10px !important;
    }

    // .ant-upload {
    //   height: 150px !important;
    // }
  }
  .learning-textarea {
    width: 100%;
    height: 135px;
  }
}
.ant-drawer-body {
  margin: 0 !important;
}

.internal-user-form__form .learning-module-form__form {
  padding: 1.5rem;
  background: $white;
  box-shadow: $primary-shadow;
  border-radius: 8px;
}

.learning-module-form__content-wrapper {
  margin: 0 1.5rem;
}

.primary {
  color: #597bcd;
}
.learning-module-form__space {
  align-items: center;
  padding: 0 8px 4px !important;
}
.learning-module-form__link {
  white-space: nowrap !important;
  color: $primary-color !important;
}
.learning-module-form__divider {
  margin: 8px 0px !important;
}
.required {
  color: red;
}
