@import "src/styles/variables";

.internal-user-list {
  h1 {
    span, button {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .internal-user-list__icons {
    opacity: 0;
    transition: opacity 0.25s ease;
    .anticon {
      margin-right: 10px;
      svg {
        font-size: 1.2rem;
      }
    }
  }
  .ant-table-wrapper {
    .ant-table-content {
      .ant-table-row:hover {
        .internal-user-list__icons {
          opacity: 1;
        }
      }
    }
  }
}



.ant-input-group-wrapper.ant-input-search.internal-user-list__search {
  input, .ant-input-group-addon .ant-btn.ant-btn-icon-only.ant-input-search-button{
    background: $white;
    border: none;
    padding: 10px;
    height: 40px;
  }
  input:focus {
    outline: none;
    box-shadow: none;
  }
}
