@import "src/styles/variables";

.ant-select.notification__status-select:not(.ant-select-customize-input) {
  border-radius: 8px;
  .ant-select-selector {
    border: none;
    border-radius: 8px;
    background-color: $select-bg;
  }
}
