@import "src/styles/variables";

.ba-documents {
  padding: 2rem;
}

.ba-documents__files {
  margin-top: 2rem;
  table {
    thead {
      th {
        width: 200px;
      }
      .ba-documents__th {
        margin-top: 1rem;
      }
    }
    tr td {
      padding: 0.5rem 0;
    }
  }
}
