@import "src/styles/variables";

.service-provider-product-detail {
  b {
    color: $primary-color;
    font-size: 1.5rem;
    display: block;
    margin-bottom: 10px;
  }

  dt {
    color: $primary-color;
  }
}

.service-provider-product-detail__label {
  color: $secondary-black;
}

.service-provider-product-detail__value {
 font-family: SFProDisplaySemibold, sans-serif;
}