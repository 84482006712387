@import "src/styles/variables";

.product-request-application-details {
  padding: 2rem;
}

.product-request-application-details__label {
  color: $secondary-black;
  margin-bottom: 5px;
}

.product-request-application-details__value {
  font-family: SFProDisplaySemibold, sans-serif;
}

.ant-select.product-request-application-details__status-select:not(.ant-select-customize-input) {
  border-radius: 8px;
  .ant-select-selector {
    border: none;
    border-radius: 8px;
    background-color: $select-bg;
  }
}
