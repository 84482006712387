@import "variables";

/* Helpers CSS */

.d-block {
  display: block !important;
}

.right-border {
  border-right: 1px solid $primary-border;
}

.d-none {
  display: none !important;
}

.float-right {
  float: right !important;
}

.text-danger {
  color: $danger-color !important;
}
.text-active {
  color: $success-color !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-family: SFProDisplaySemibold, sans-serif;
}
.text-italic {
  font-style: italic !important;
}

.text-secondary-black {
  color: $secondary-black !important;
}
.text-primary-black {
  color: $primary-black !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-grey {
  color: #9e9e9e;
}
.text-light-grey {
  color: $light-grey;
}

.text-dark-grey {
  color: $dark-grey;
}

.text-white {
  color: #ffffff !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.bb-5 {
  border-bottom: ($spacer-y * 1.25) solid $primary-color !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.primary-black {
  color: $primary-black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

/* Padding */

.p-0 {
  padding: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

/* Margins */

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: ($spacer-y * 0.25) !important;
}

.mt-2 {
  margin-top: ($spacer-y * 0.5) !important;
}

.mt-3 {
  margin-top: ($spacer-y * 0.75) !important;
}

.mt-4 {
  margin-top: ($spacer-y * 1) !important;
}

.mt-5 {
  margin-top: ($spacer-y * 1.25) !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2 {
  margin-bottom: ($spacer-y * 0.5) !important;
}

.mb-3 {
  margin-bottom: ($spacer-y * 0.75) !important;
}

.mb-4 {
  margin-bottom: ($spacer-y * 1) !important;
}

.mb-5 {
  margin-bottom: ($spacer-y * 1.25) !important;
}

.ml-2 {
  margin-left: ($spacer-x * 0.5) !important;
}

.ml-3 {
  margin-left: ($spacer-x * 0.75) !important;
}

.ml-4 {
  margin-left: ($spacer-x * 1) !important;
}

.ml-5 {
  margin-left: ($spacer-x * 1.25) !important;
}

.mr-1 {
  margin-right: ($spacer-x * 0.25) !important;
}

.mr-2 {
  margin-right: ($spacer-x * 0.5) !important;
}

.mr-3 {
  margin-right: ($spacer-x * 0.75) !important;
}

.mr-4 {
  margin-right: ($spacer-x * 1) !important;
}

.mr-5 {
  margin-right: ($spacer-x * 1.25) !important;
}
.opacity-1 {
  opacity: 1 !important;
}

.pointer {
  cursor: pointer;
}

/* color code */
.active,
.payment-completed,
.read {
  color: #26ad10 !important;
  .ant-select-selector {
    background-color: #e5ffe3 !important;
  }
  .ant-select-arrow {
    color: #26ad10;
  }
}
.inactive {
  color: #ff6654 !important;
  .ant-select-selector {
    background-color: #ffeae2 !important;
  }
  .ant-select-arrow {
    color: #ff6654;
  }
}

.incomplete {
  color: #3114ae !important;
  .ant-select-selector {
    background-color: #c8c2e8 !important;
  }
  .ant-select-arrow {
    color: #3114ae;
  }
}

.rejected {
  color: #d55353 !important;
  .ant-select-selector {
    background-color: #f2d2d1 !important;
  }
  .ant-select-arrow {
    color: #d55353;
  }
}
.under-review {
  color: #1b9ad3 !important;
  .ant-select-selector {
    background-color: #c9e3f0 !important;
  }
  .ant-select-arrow {
    color: #1b9ad3;
  }
}
.pending-e_sign_admin,
.pending-e_sign_ba,
.unread {
  color: #d78e15 !important;
  .ant-select-selector {
    background-color: #f8f8f0;
  }
  .ant-select-arrow {
    color: #d78e15;
  }
}

.new {
  color: #333b6a !important;
  .ant-select-selector {
    background-color: #f1f1d3 !important;
  }
  .ant-select-arrow {
    color: #333b6a;
  }
}
.under_review {
  color: #d68311 !important;
  .ant-select-selector {
    background-color: #fff9e0 !important;
  }
  .ant-select-arrow {
    color: #d68311;
  }
}
.email_verified {
  color: #7268bc;
}

.mobile_verified {
  color: #79a3b1;
}

.aadhaar_completed,
.aadhaar_verified {
  color: #e066ba;
}
.pan_verified {
  color: #6891d5;
}

.pending_credential_verification {
  color: #ea907a;
}

.credentials_verified {
  color: #019222;
}

.bank_details_verification_complete {
  color: #049372;
}
.bank_details_complete {
  color: #60bc12;
}
.personal_details_complete {
  color: #829828;
}

.qualification_details_complete {
  color: #1cb5b0;
}

.profile_pic_uploaded {
  color: #3b6fb7;
}
.references_complete {
  color: #68bc93;
}
