@import "src/styles/variables";
.axis-bank-form {
  .axis-bank-form__btn {
    display: flex;
    position: absolute;
    top: 88%;
    left: 68%;
  }
  .ant-row {
    .attachment-upload {
      .attachment-upload__input {
        height: 120px !important;
        background: #fafafa 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 6px #00000008;
        border: 2px dashed #d3d3d3;
        border-radius: 6px;
        opacity: 1;
        color: $primary-color !important;

        span {
          margin-top: 1.5rem;
        }
        .anticon-upload {
          color: $primary-color !important;
        }
      }
    }
  }
}

.ant-drawer-content {
  padding: 1.5rem;
}
