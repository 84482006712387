@import "src/styles/variables";
.dashboard {
  .dashboard-data {
    width: 100%;
    align-content: space-between;

    .ant-row {
      justify-content: space-between;

      .ant-col {
        .ant-card {
          border-radius: 8px;
          .ant-card-body {
            width: 200px;
            height: 151px;
            text-align: center;
            border-radius: 3px;
            p {
              color: #40414e !important;
            }
          }
        }
      }
    }
  }
  .dashboard__onboarding,
  .dashboard__customer_line_graph {
    background-color: $white;
    padding: 2rem;
    .ant-select {
      margin-right: 14px;
      .ant-select-selector {
        border: 0.1px solid #656b7a !important;

        border-radius: 8px;
        width: 115px;
        height: 36px;
        background-color: $white;
        text-align: center;
        padding: 0.2rem;

        opacity: 1;
      }
      .ant-select-selection-placeholder {
        color: #656b7a;
      }
    }
    .ba-onboarding__select {
      position: relative;
      left: 75%;
    }
  }
  .ant-table-row,
  .ant-table-thead {
    .ant-table-cell {
      text-align: center !important;
      color: $primary-color;
    }
  }
  .ant-table-wrapper {
    padding: 2rem !important;
    background-color: $white;
    .ant-table-content {
      border: 1px solid $light-grey;
      border-radius: none !important;
    }
    .ant-table-bordered {
      box-shadow: none !important;
    }
  }
}
