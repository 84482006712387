@import "src/styles/variables";

.modal-field {
  .ant-modal-content {
    .ant-modal-body {
      padding: 10px !important;
    }
    border-radius: 16px !important;
    padding: 1rem;
    .custom-form {
      text-align: center;
      .custom-form__content {
        margin-bottom: 35px !important;
      }

      .alert-icon {
        width: 50px;
        padding-bottom: 20px;
      }
    }
    .ant-btn {
      width: 90px;
      left: 24% !important;
    }
  }
}
