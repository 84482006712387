@import "src/styles/variables";

.ba-personal-details {
  padding: 2rem;
  > .ba-personal-details__section {
    &:not(:last-child) {
      border-bottom: 1px solid $primary-border;
      padding-bottom: 1.5rem;
    }
    &:not(:first-child) {
      padding-top: 1.5rem;
    }
  }
}

.ba-personal-details__label {
  color: $secondary-black;
}

.ba-personal-details__value {
  font-family: SFProDisplaySemibold, sans-serif;
}

.ba-perosnal-details__edit {
  .anticon {
    cursor: pointer;
    svg {
      font-size: 1.2rem;
    }
  }
}
