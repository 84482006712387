@import "src/styles/variables";

.survey-questions {
  .ant-select.survey-questions__status-select:not(.ant-select-customize-input) {
    border-radius: 8px;
    .ant-select-selector {
      border: none;
      border-radius: 8px;
      background-color: $select-bg;
    }
  }
}
